import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import TermsAndConditions from "../components/main/TermsAndConditions"

const TermsAndConditionsPage = () => (
  <Layout>
    <Seo title="Terms and Conditions" />
    <TermsAndConditions />
  </Layout>
)

export default TermsAndConditionsPage
